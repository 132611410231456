html,
body {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #e4ff3f !important;
}

#root {
  width: 80%;
  height: 100%;
  padding-bottom: 50px;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
  cursor: default;
}

.marker-img {
  cursor: pointer;
}

.marker-img:hover {
  zoom: 1.35;
}

.chart-cointainer {
  background-color: black;
}

h1 {
  text-align: center;
}

.navbar-expand .navbar-nav .nav-link {
  font-size: 1.5rem;
  font-weight: bolder;
}

.navbar-nav .nav-link.active {
  text-decoration: underline;
}